.home-container {
  display: flex;
  justify-content: flex-start;
  align-content: center;
  flex-direction: column;
  height: 100%;
  min-height: 100vh;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  background-color: transparent;
}

.home-wrapper {
  display: grid;
  width: 100%;
  //margin-left:100px;
  justify-items: center;
  height: 100%;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  grid-template-rows: repeat(auto-fill, minmax(350px, 1fr));
}

.home-render-dashboards {
  display: flex;
  width: 100%;
  margin-bottom: 100px;
  justify-content: center;
  align-items: center;

}

.home-render-dashboards-button {
  display: flex;
  width: auto;
  height: 30px;
  justify-content: center;
  align-items: center;
  padding: 10px 16px;
  background-color: transparent;
  margin: 0 15px;

  &.active {
    background-color: rgba(0, 0, 0, 0.2);
  }

  &:hover {

    background-color: rgba(0, 0, 0, 0.2);
    cursor: pointer;
  }

}

.general-cards-container {
  padding: 20px 50px;
  box-sizing: border-box;
  width: 80%;
  height: 80%;
  position: relative;

  .online {
    width: 100%;
    height: 100%;
    opacity: 0.2;
    position: absolute;
    left: 0;
    top: 0;
    background-color: green;
  }
}

.dashboard-list-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-top: 30px;
  //background-color: white;
  //padding:0 20px;
  box-sizing: border-box;
}

.dashboard-inner-wrapper {
  box-sizing: border-box;
  padding: 20px;
  height: 100%;
  width: 100%;
  display: block;
}

.dashboard-list-tabs {
  border-top-right-radius: 5px;
  width: 100%;
  height: auto;
  display: block;
  //background-color: #EEEEEE;

  li {
    display: inline-block;
    padding: 16px 20px;

    &.left {
      border-top-left-radius: 5px;
    }
  }
}

.dashboard-list-search-container {
  display: block;
  width: 100%;
  height: 50px;
  margin: 10px 0;
}

.dashboard-list-search {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 200px;
  height: 100%;
  border-radius: 5px;
  padding: 0 10px;
  width: 20%;
  min-width: 200px;

  input {
    height: 90%;
    width: 80%;
    border: none;
    outline-width: 0;

  }
}

.dashboard-list-header {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  display: grid;
  width: auto;
  height: auto;
  grid-template-columns: repeat(10, 1fr);
  background-color: #eeeeee;

  span {
    display: inline-block;
    height: auto;
    width: 100%;
    padding: 20px 10px;
  }

}

.dashboard-list-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  min-height: 50px;
  max-height: 200px;
  border-bottom: 2px solid;

  .list-action {
    height: 0;
    position: relative;
    bottom: 0;
    pointer-events: none;
    display: flex;
    align-items: center;
    padding: 0 10px;
    transition: 400ms ease-in-out all;
    transition-delay: 300ms;



    .action-button {
      opacity: 0;
      border-radius: 3px;
      position: relative;
      display: inline-block;
      height: auto;
      padding: 10px 16px;
      transition: 200ms ease-in-out all;

      &.positive{
        background-color: green;
      }

      &.caution{
        background-color: orange;
      }

    }
  }

  &.open {
    height: auto;

    .list-action {
      height:100px;
      min-height: 100px;
      pointer-events: all;
      padding: 20px 10px;
      transition: 400ms ease-in-out all;

      .action-button {
        opacity: 1;
        position: relative;
        transition: 200ms ease-in-out all;
        transition-delay: 300ms;

        &:hover {
          cursor: pointer;
        }

      }
    }
  }
}

.dashboard-list-inner-wrapper {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(10, 1fr);
  height: auto;

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }

  .dashboard-list-cell {
    width: auto;
    height: auto;
    padding: 20px 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    &.center {
      justify-content: center;
    }

    p {
      display: flex;
      border-radius: 3px;

      &.link {
        cursor: pointer;
        color: #4460e6;
      }

      &.online {
        background-color: green;
        padding: 2px 5px;
      }

      &.offline {
        background-color: red;
        padding: 2px 5px;
      }
    }
  }
}


@media screen and (max-width: 1100px) {

  .home-container {
    margin-top: 80px;
  }

  .dashboard-list-body{
    overflow-x: scroll;
    overflow-y: hidden;
    min-width: 100%;
  }

  .dashboard-list-wrapper{
    min-width: calc(10 * 150px);
  }

  .dashboard-list-inner-wrapper{
    grid-template-columns: repeat(10, minmax(150px, 1fr));
  }

  .dashboard-list-header{
    grid-template-columns: repeat(10, minmax(150px, 1fr));
    min-width: calc(10 * 150px);
  }

}


@media screen and (max-width: 450px) {

  .home-container {
    margin-top: 50px;
  }

  .dashboard-list-wrapper{
    min-width: calc(10 * 45vw);
  }

  .dashboard-list-inner-wrapper{
    grid-template-columns: repeat(10, minmax(45vw, 1fr));
  }

  .dashboard-list-header{
    grid-template-columns: repeat(10, minmax(45vw, 1fr));
    min-width: calc(10 * 45vw);
  }

}

.animated-text{

  &.negative-light{
    animation: animate-text-light-negative 900ms ease-in;
  }

  &.negative-dark{
    animation: animate-text-dark-negative 900ms ease-in;

  }

  &.positive-light{
    animation: animate-text-light-positive 900ms ease-in;
  }

  &.positive-dark{
    animation: animate-text-dark-positive 900ms ease-in;

  }

}

@keyframes animate-text-dark-positive {
  from {color:green}
  to{color: white}
}

@keyframes animate-text-dark-negative {
  from {color:red}
  to{color: white}
}



@keyframes animate-text-light-negative {
  from {color: red
  }
  to{color: #1a1a1a}
}

@keyframes animate-text-light-positive {
  from {color: #00a800
  }
  to{color: #1a1a1a}
}


.fade-in-list-enter {
  opacity: 0;
}

.fade-in-list-enter-done {
  opacity: 1;
  transition: 700ms ease-in-out all;
}

.fade-in-list-enter-active {
  opacity: 1;
  transition: 700ms ease-in-out all;
}

.fade-in-list-exit {
  opacity: 1;
}

.fade-in-list-exit-active {
  opacity: 0;
  transition: 700ms ease-in-out all;
}
